/*=========================================================================================
  File Name: moduleCourseClassActions.js
  Description: CourseClass Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios.js";
export default {

  GetItemById({ commit}, itemid ) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("/Biadjos/GetQrCodeByBiadjoId?Id=" + itemid)
        .then(response => {
          if (response.status == 200) {
            commit("SET_QrCode", response.data);
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

};
