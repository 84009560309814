<template>
<div>

 <div class="vx-row">
  <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
      <label class="text-sm opacity-75">{{ $t("TripCode") }}</label>
      <vs-input :dir="$vs.rtl ? 'ltr' : 'ltr'" class="mt-1 w-full" v-model="Model.code" disabled />
  </div>
  <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
      <label class="text-sm opacity-75">{{ $t("TripDate") }}</label>
      <vs-input :dir="$vs.rtl ? 'ltr' : 'ltr'" class="mt-1 w-full" v-model="Model.trip_Date" disabled />
  </div>
 </div>
<vx-card
      ref="filterCard"
       v-bind:title="$t('UserData')"
      class="user-list-filters mb-8"
      collapse-action
    >

      <div class="vx-row">
          <div class="vx-col md:w-1/5 sm:w-1/2 w-full">
            <label class="text-sm opacity-75">{{ $t("Name") }}</label>
            <vs-input :dir="$vs.rtl ? 'ltr' : 'ltr'" class="mt-1 w-full" v-model="Model.user_Name" disabled/>
         </div>

        <div class="vx-col md:w-1/5 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">{{ $t("ContactNumber") }}</label>
          <vs-input :dir="$vs.rtl ? 'ltr' : 'ltr'"
            type="text"  class="mt-1 w-full" v-model="Model.user_Phone" disabled />
        </div>

      </div>
  </vx-card>
  <vx-card
      ref="filterCard"
       v-bind:title="$t('DriverData')"
      class="user-list-filters mb-8"
      collapse-action
    >
      <div class="vx-row">

        <div class="vx-col md:w-1/5 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">{{ $t("Name") }}</label>
          <vs-input class="mt-1 w-full" :dir="$vs.rtl ? 'ltr' : 'ltr'"  v-model="Model.driver_Name" disabled/>
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full">
            <label class="text-sm opacity-75">{{ $t("Nationalities") }}</label>
            <vs-input :dir="$vs.rtl ? 'ltr' : 'ltr'" class="mt-1 w-full" v-model="Model.driver_Nationality" disabled/>
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full">
            <label class="text-sm opacity-75">{{ $t("NationalID") }}</label>
            <vs-input :dir="$vs.rtl ? 'ltr' : 'ltr'" class="mt-1 w-full" v-model="Model.driver_National_ID" disabled />
         </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">{{ $t("ContactNumber") }}</label>
          <vs-input :dir="$vs.rtl ? 'ltr' : 'ltr'"  type="text" class="mt-1 w-full"  v-model="Model.driver_Phone" disabled
          />
        </div>



      </div>
</vx-card>

<vx-card
      ref="filterCard"
       v-bind:title="$t('TransportVehicleInformation')"
      class="user-list-filters mb-8"
      collapse-action
    >
      <div class="vx-row">

        <div class="vx-col md:w-1/5 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">{{ $t("Type") }}</label>
          <vs-input class="mt-1 w-full" :dir="$vs.rtl ? 'ltr' : 'ltr'"  v-model="Model.car_Type" disabled/>
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">{{ $t("ImageUrl") }}</label>
          <img v-if="Model.car_Image" :src="Model.car_Image" width="80" height="80" alt=""/>
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full">
            <label class="text-sm opacity-75">{{ $t("PlateNumber") }}</label>
            <vs-input :dir="$vs.rtl ? 'ltr' : 'ltr'" class="mt-1 w-full" v-model="Model.car_Plate" disabled />
         </div>


      </div>
</vx-card>
<vx-card
      ref="filterCard"
       v-bind:title="$t('FlightInformation')"
      class="user-list-filters mb-8"
      collapse-action
    >
      <div class="vx-row">

        <div class="vx-row md:w-1/5 sm:w-1/2 w-full">
          &nbsp;&nbsp;&nbsp; <h5 >{{ $t("TransferFrom") }}:</h5>
          &nbsp;
          <h6 >{{ Model.start_Address }}</h6>
        </div>
        <div class="vx-row md:w-1/5 sm:w-1/2 w-full">
           <h5 >{{ $t("Location") }}:</h5>
           &nbsp;
          <h6 >{{ Model.start_Location }}</h6>
        </div>
        <div class="vx-row md:w-1/5 sm:w-1/2 w-full">
           <h5 >{{ $t("Floor") }}:</h5>
           &nbsp;
          <h6 >{{ Model.start_Floor }}</h6>
        </div>
        <div class="vx-row md:w-1/5 sm:w-1/2 w-full">
           <h5 >{{ $t("NumberLoadingWorkers") }}:</h5>
           &nbsp;
          <h6 >{{ Model.start_Workers_Count }}</h6>
        </div>
        <div class="vx-row md:w-1/5 sm:w-1/2 w-full">
           <h5 >{{ $t("Notes") }}:</h5>
           &nbsp;
          <h6 >{{ Model.start_Notes }}</h6>
        </div>



      </div>
      <br>
      <div class="vx-row">

<div class="vx-row md:w-1/5 sm:w-1/2 w-full">
  &nbsp;&nbsp;&nbsp; <h5 >{{ $t("TransferTo") }}: </h5>
  &nbsp;
  <h6 >{{ Model.end_Address }}</h6>
</div>
<div class="vx-row md:w-1/5 sm:w-1/2 w-full">
   <h5 >{{ $t("Location") }}:</h5>
   &nbsp;
  <h6 >{{ Model.end_Location }}</h6>
</div>
<div class="vx-row md:w-1/5 sm:w-1/2 w-full">
   <h5 >{{ $t("Floor") }}:</h5>
   &nbsp;
  <h6 >{{ Model.end_Floor }}</h6>
</div>
<div class="vx-row md:w-1/5 sm:w-1/2 w-full">
   <h5 >{{ $t("NumberLoadingWorkers") }}:</h5>
   &nbsp;
  <h6 >{{ Model.end_Workers_Count }}</h6>
</div>
<div class="vx-row md:w-1/5 sm:w-1/2 w-full">
   <h5 >{{ $t("Notes") }}:</h5>
   &nbsp;
  <h6 >{{ Model.end_Notes }}</h6>
</div>



</div>
<br>
<center>
  <div class="vx-row md:w-1/5 sm:w-1/2 w-full">
   <h5 >{{ $t("TripDistance") }}:</h5>
   &nbsp;
  <h6 >{{ Model.distance }} KM</h6>
</div>
</center>
<br>
<div class="vx-row md:w-1/5 sm:w-1/2 w-full">
   <h5 >{{ $t("LoadTypes") }}:</h5>
   &nbsp;
  <h6 >{{ Model.loadTypeName }} </h6>
</div>
<center>
  <div class="vx-row md:w-1/5 sm:w-1/2 w-full">
   <h5 >{{ $t("InventoryDetails") }}</h5>

</div>
</center>

<vs-table
      ref="table"
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="Model.biadjoInventoryList"
    >



      <template slot="thead">
        <vs-th>{{ $t("ConfirmReceiptSender") }}</vs-th>
        <vs-th>{{ $t("Category") }}</vs-th>
        <vs-th>{{ $t("Type") }}</vs-th>
        <vs-th>{{ $t("Total") }}</vs-th>
        <vs-th sort-key="ImageUrl">{{ $t("ImageUrl") }}</vs-th>
        <vs-th>{{ $t("Notes") }}</vs-th>
      </template>

      <template slot-scope="{ data }">
  <tbody>
    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
      <vs-td>
        <input type="checkbox" :checked="tr.isDriverConfirm" disabled />
      </vs-td>
      <vs-td> {{tr.cargoType}}</vs-td>
      <vs-td> {{tr.materialType}}</vs-td>
      <vs-td> {{tr.cargoCount}}</vs-td>
      <vs-td>
        <img v-if="tr.imageUrl" :src="tr.imageUrl" width="80" height="80" alt=""/>
      </vs-td>
      <vs-td> {{tr.notes}} </vs-td>
    </vs-tr>
  </tbody>
</template>
    </vs-table>
</vx-card>
</div>
</template>

<script>
import modulePickupQRCode from "@/store/PickupQRCode/modulePickupQRCode";

export default {
  data() {
    return {
      Model: {},
    };
  },

  created() {
    debugger;
    if (!modulePickupQRCode.isRegistered) {
      this.$store.registerModule("modulePickupQRCodeList", modulePickupQRCode);
      modulePickupQRCode.isRegistered = true;
    }
debugger;

const ID = this.$route.params.ID;
console.log('this.$route.params.ID',this.$route.params.ID);
console.log('ID',ID);
    debugger;
    if (ID != undefined && ID != 0) {
      debugger;
      this.$store.dispatch("modulePickupQRCodeList/GetItemById", ID).then((res) => {
        debugger;
        this.Model = res.data;
        this.$vs.loading.close();
      });
    } else if (ID == undefined) {
      ID == 0;
    }

  }
};
</script>

<style>
</style>
